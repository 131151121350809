/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
//import React from 'react'
import { Route, Switch } from 'react-router-dom';


import AuthUser from '../../components/AuthUser';
import JornadaCientificaLista from './jornada_cientifica_lista';

const JornadacientificaIndex = ({ match }) => {

    // eslint-disable-next-line no-unused-vars
    const { verificarPermissao } = AuthUser();
    // eslint-disable-next-line no-unused-vars
    const [permissaoVer, setPermissaoVer] = useState(false);
    const [permissaoAtivar, setPermissaoAtivar] = useState(false);
    const [permissaoRemover, setPermissaoRemover] = useState(false); 
    const [permissaoPagamentoVer, setPermissaoPagamentoVer] = useState(false);

    useEffect(() => {

        setPermissaoVer(verificarPermissao('TURMA','ver'));
        setPermissaoPagamentoVer(verificarPermissao('PAGAMENTOS','ver'));
        setPermissaoAtivar(verificarPermissao('TURMA','ativar'));
        setPermissaoRemover(verificarPermissao('TURMA','remover'));

    },);

    return (
    <Switch>

        <Route path={`${match.url}`} render={(props) => <JornadaCientificaLista {...props} permissaoVer={permissaoVer} permissaoAtivar={permissaoAtivar} permissaoRemover={permissaoRemover} permissaoPagamentoVer={permissaoPagamentoVer} />} />

    </Switch>)
}

export default JornadacientificaIndex