import axios from 'axios';
import { useState, useEffect } from 'react';

export default function AuthUser() {    

    //const navigate = useNavigate();  
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [empresa_selecionada, setEmpresa] = useState(getUser());
    const [logoTipo, setLogoTipo] = useState();
    const [user_permissoes, setPermissoes] = useState([]);
    const [banca_permissao, setBancaPermissao] = useState(false);

    const url_root = "https://admin.hrl.ao";
    //const url_root = "https://projetos-artefe.excelproexpert.ao";
    //const url_root = "http://192.168.1.200:8000";
    //const url_root = "http://127.0.0.1:8000";
    //const url_root = "https://admin.excelproexpert.ao";
    //const url_root = "https://trainningbusiness.kahewa.ao";


    //This is case setUser depends on token value so
    //it makes that assincronous wait for the value of token

    useEffect(() => {
        setLogoTipo('/static/media/logo-white.6d4161d9bceda0741c4e.png');
        setUser(getUser());
        setBancaPermissao(getBancaPermissao());
        setEmpresa(getEmpresa());
        setPermissoes(getPermissoes());
    }, [token]);

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    function getUser() {
        const userString = sessionStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail || null; // Set to null if userDetail is false
    }

    function getEmpresa() {
        const empresaString = sessionStorage.getItem('empresa');
        const empresaDetail = JSON.parse(empresaString);
        return empresaDetail || null; // Set to null if userDetail is false
    }

    function getBancaPermissao() {
        const bancaString = sessionStorage.getItem('banca_permissao');
        const bancaDetail = JSON.parse(bancaString);
        return bancaDetail || null; // Set to null if userDetail is false
    }

    function getPermissoes() {
        const permissoesString = sessionStorage.getItem('permissoes');
        const permissaoDetail = JSON.parse(permissoesString);
        return permissaoDetail || null; // Set to null if permissaiDetail is false
    }

    const saveToken = (user, permissoes, token, empresa, banca_permissao) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('empresa', JSON.stringify(empresa));
        sessionStorage.setItem('permissoes', JSON.stringify(permissoes));
        sessionStorage.setItem('banca_permissao', JSON.stringify(banca_permissao));
        setToken(token);
        setPermissoes(permissoes);
        setEmpresa(empresa);
        setBancaPermissao(banca_permissao);
        //navigate('/dashboard');
    }

    const logout = () => {
        sessionStorage.clear();
        //navigate('/entrar');
    }

    const http = axios.create({
        baseURL: url_root + "/api",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    });


    const verificarPermissao = (codigo, operacao) => {

        //console.log('Confirmar permissões, ao verificar');
        //console.log(user_permissoes);

        // Percorre o array de permissões
        for (let i = 0; i < user_permissoes.length; i++) {
            const permissao = user_permissoes[i];

            let op = false;

            if (operacao.toLowerCase() == 'ver') {
                op = permissao.ver == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'atualizar') {
                op = permissao.atualizar == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'adicionar') {
                op = permissao.adicionar == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'remover') {
                op = permissao.remover == 1 ? true : false;
            } else if (operacao.toLowerCase() == 'ativar') {
                op = permissao.ativar == 1 ? true : false;
            } else {
                op = false;
            }

            // Verifica se o código e a operação correspondem
            if (
                permissao.accao.codigo.toLowerCase() == codigo.toLowerCase() && op
            ) {
                return true; // Permissão concedida
            }
        }

        return false; // Permissão não concedida
    };


    return {
        setToken: saveToken,
        token,
        user,
        user_permissoes,
        getToken,
        http,
        logout,
        url_root,
        logoTipo,
        verificarPermissao,
        empresa_selecionada,
        banca_permissao,
    }
}