/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../components/Spinner";
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import PropTypes from 'prop-types';

import { format } from 'date-fns';

//import formatNumber from 'format-number';

import {
  Excel,
  Pdf,
} from "../../EntryFile/imagePath";

const TurmaEdit = ({ permissaoAtivar, permissaoAtualizar, permissaoPagamentoVer, permissaoPagamentoAdicionar }) => {

  const history = useHistory();

  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao } = AuthUser();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [galeria, setGaleria] = useState(1);
  const [situacao, setSituacao] = useState(1);
  const [nota, setNota] = useState(0);
  const [computador, setComputador] = useState('');
  const [inscricao_selecionada, setInscricaoID] = useState(null);
  const [curso_selecionado, setCursoSelecionado] = useState(1);
  const [sala_selecionada, setSalaSelecionada] = useState(1);
  const [periodo_selecionado, setPeriodoSelecionado] = useState(1);
  const [sabado_selecionado, setSabadoSelecionado] = useState(1);
  const [entrega_selecionado, setEntregaSelecionada] = useState(1);
  const [status_selecionado, setStatusSelecionado] = useState(1);
  const [promocao_selecionada, setPromocaoSelecionada] = useState(1);
  const [pagamento_selecionado, setPagamentoSelecionado] = useState(1);
  const [tipoPagamento, setTipoPagamento] = useState(1);

  const [loading, setLoading] = useState(false);

  const [nomeFilter, setNomeFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [nifFilter, setNifFilter] = useState('');

  const [imagem, setSelectedFile] = useState(null);
  const [imagem2, setSelectedFile2] = useState(null);//comprovativo de pagamento
  const [imagem20, setSelectedFile20] = useState(null);//comprovativo de pagamento 2
  const [imagem200, setSelectedFile200] = useState(null);//comprovativo de pagamento 2
  const [imagem3, setImagem3] = useState(null);
  const [imagemPreview, setimagemPreview] = useState(null);
  const [imagemPreview2, setimagemPreview2] = useState(null);
  const [imagemPreview20, setimagemPreview20] = useState(null);
  const [imagemPreview200, setimagemPreview200] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [salas, setSalas] = useState([]);
  const [turma, setTurma] = useState(null);

  const [pagamentos_turma, setPagamentosTurma] = useState([]);
  const [pagamentos_formando, setPagamentosFormando] = useState([]);

  /* Formando novo */
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [tipo_pagamento, setPagamento] = useState(2);
  const [telefone, setTelefone] = useState('');
  const [data_nasc, setDataNasc] = useState(new Date());
  const [data_insc, setDataInsc] = useState(new Date());
  const [data_insc2, setDataInsc2] = useState(new Date());
  const [data_insc3, setDataInsc3] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [nif, setNIF] = useState('');
  const [valor, setValor] = useState(0);
  const [valor2, setValor2] = useState(0);
  const [valor3, setValor3] = useState(0);
  const [observacao, setObservacao] = useState('');
  const [observacao2, setObservacao2] = useState('');
  const [observacao3, setObservacao3] = useState('');
  /* Formando novo */

  const [inputs, setInputs] = useState([]);

  const [formandoSelecionado, setFormandoSelecionado] = useState(null);

  /* TABELA MODAL FORMADORES */

  const [colaborador, setColaborador] = useState([]);
  const [formadores, setFormadores] = useState([]);
  const [formandos, setFormandos] = useState([]);
  const [formandos_lista, setFormandoLista] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [checkGeral, setCheckGeral] = useState(false);
  const [checkGeral2, setCheckGeral2] = useState(false);
  const [colaboradoresSelecionados, setColaboradoresSelecionados] = useState([]);
  const [formandosSelecionados, setFormandosSelecionados] = useState([]);

  // Function to handle the "checkgeral" checkbox change
  const handleCheckGeralChange = () => {
    // Toggle the checked state
    setCheckGeral(!checkGeral);

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral;
      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        selectedColaboradores.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);

  };

  const handleCheckGeralChange2 = () => {
    // Toggle the checked state
    setCheckGeral2(!checkGeral2);

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-formando");

    // Create a new array to store the selected collaborator IDs
    const selectedFormnandos = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checkGeral2;
      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        selectedFormnandos.push(checkbox.dataset.id);
      }
    });

    // Update the state with the selected collaborator IDs
    setFormandosSelecionados(selectedFormnandos);

  };

  // Function to handle the "checkBoxes from table" checkbox change
  const handleCheckBoxChange = () => {

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-colaborador");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        const colaboradorId = checkbox.dataset.id;
        console.log('Colaborador ID selecionado:', colaboradorId);
        selectedColaboradores.push(colaboradorId);
      }
    });

    // Update the state with the selected collaborator IDs
    setColaboradoresSelecionados(selectedColaboradores);

  }

  const handleCheckBoxChange2 = () => {

    // Get all the "check-colaborador" checkboxes
    const checkboxes = document.querySelectorAll(".check-formando");

    // Create a new array to store the selected collaborator IDs
    const selectedColaboradores = [];

    // Set the checked state of each checkbox to match "checkgeral"
    checkboxes.forEach((checkbox) => {

      if (checkbox.checked) {
        // Add the ID to the selectedColaboradores array
        const colaboradorId = checkbox.dataset.id;
        console.log('Formando ID selecionado:', colaboradorId);
        selectedColaboradores.push(colaboradorId);
      }
    });

    // Update the state with the selected collaborator IDs
    setFormandosSelecionados(selectedColaboradores);

  }

  const vincularFormadorturma = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (colaboradoresSelecionados.length > 0) {

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende associar os formadores selecionados a esta turma ?.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, associar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/turma/formadores/associar', {
                colaboradoresSelecionados, id
              });

              getFormadores();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao associar formadores ao turma. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos um formador', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormadorturma = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (colaboradoresSelecionados.length > 0) {

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende Desassociar os formadores selecionados da turma ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, associar!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/turma/formadores/desassociar', {
                colaboradoresSelecionados, id
              });

              getFormadores();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao associar formadores ao turma. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos um formador', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const desvincularFormandosTurma = async () => {

    if (permissaoAtualizar) {
      // Exibe a mensagem de confirmação

      if (formandosSelecionados.length > 0) {

        Swal.fire({
          title: "Tem certeza ?",
          text: "Pretende Desassociar os formandos selecionados da turma, isso removera tambem todos os pagamentos feitos relacionados a turma e estes formandos, quer continuar ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, continuar a remover!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Envie uma solicitação para a API para eliminar os usuários selecionados
              const response = await http.post('/turma/formandos/desassociar', {
                formandosSelecionados, id
              });

              getFormandos();

              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } catch (error) {
              console.error(error);

              toast.error('Ocorreu um erro ao remover formandos da turma. Por favor, tente novamente mais tarde.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          }
        });

      } else {

        toast.error('Tem de selecionar pelo menos um formando', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  /* FIM CONSTANTES TABELA MODAL FORMADORES */

  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const navegarParaLista = () => {
    history.push('/dream-pos/turma/turmalista');
  };

  const irParaFormador = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/users/newformadoredit/' + id);

  }

  const irParaFormando = (id) => {

    const element = document.querySelector('.btn-close');

    // Check if the element exists before triggering the click event
    if (element) {
      // Trigger a click event
      element.click();
    }

    history.push('/dream-pos/users/newformandoedit/' + id);

  }

  useEffect(() => {

    pagamentos_turma;

    getturma();

  }, []);

  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const getPagamentos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append('turma_id', id);
      formData.append('inscricao_id', inscricao_selecionada);


      console.log('Turma e inscrição :');
      console.log(id);
      console.log(inscricao_selecionada);

      const response = await http.post('/turma/pagamentos', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response.data);
      setPagamentosTurma(response.data.pagamentos);

      setLoading(false);


    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getPagamentosFormando = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append('inscricao_id', inscricao_selecionada);

      console.log('Inscrição :');
      console.log(inscricao_selecionada);

      const response2 = await http.post('/turma/pagamentos/formando', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response2.data);
      setPagamentosFormando(response2.data.pagamentos);

      console.log("Pagamentos do formando");
      console.log(pagamentos_formando);

      setLoading(false);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getturma = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res1 = await http.get('/salas_ativas');
      setSalas(res1.data.salas);

      const res2 = await http.get('/cursos_ativos');
      setCursos(res2.data.cursos);

      const res = await http.get('/turma/' + id);
      setTurma(res.data.turma);
      setCursoSelecionado(res.data.turma.curso_id);
      setSalaSelecionada(res.data.turma?.sala_id);
      setInputs(res.data.turma);
      setimagemPreview(res.data.turma.imagem);
      setImagem3(res.data.turma.imagem);

      setPeriodoSelecionado(res.data.turma.periodo);
      setSabadoSelecionado(res.data.turma.sabados);
      setEntregaSelecionada(res.data.turma.modo);
      setStatusSelecionado(res.data.turma.status);
      setPromocaoSelecionada(res.data.turma.promocao);
      setPagamentoSelecionado(res.data.turma.paga_coletivo);
      setGaleria(res.data.turma.galeria);

      const resultado = await http.get('/formadores/curso/' + res.data.turma.curso_id);
      setColaborador(resultado.data.formadores);

      getFormadores();

      getFormandos();

      getPagamentos();

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getFormadores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/formadores/turma/' + id);

      setFormadores(res.data.formadores);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  const getFormandos = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/formandos/turma/' + id);

      setFormandos(res.data.formandos);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Utilizador não autenticado.');
      } else {
        console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleAtivarDesativar = async (id, newState) => {

    if (permissaoAtivar) {

      try {

        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append('id', id);
        formData.append('estado', estado);

        const response = await http.post('/turma/activate', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getturma();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao atualizar o estado do turma. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectGaleriaChange = (valor) => {
    setGaleria(valor);
  }

  const handleSelectCursoChange = (valor) => {

    setCursoSelecionado(valor);

    // Encontre o objeto correspondente com base no ID
    const selectedObject = cursos.find(obj => obj.id === parseInt(valor, 10));

    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (selectedObject) {
      inputs.preco = selectedObject.preco;
      setimagemPreview(selectedObject.imagem);
      setImagem3(selectedObject.imagem);
    }

  }

  const handleSelectSalaChange = (valor) => {
    setSalaSelecionada(valor);
  }

  const handleSelectPeriodoChange = (valor) => {
    setPeriodoSelecionado(valor);
  }

  const handleSelectSabadoChange = (valor) => {
    setSabadoSelecionado(valor);
  }

  const handleSelectEntregaChange = (valor) => {
    setEntregaSelecionada(valor);
  }

  const handleSelectStatusChange = (valor) => {
    setStatusSelecionado(valor);
  }

  const handleSelectPromocaoChange = (valor) => {
    setPromocaoSelecionada(valor);
  }

  const handleSelectPagamentoChange = (valor) => {
    setPagamentoSelecionado(valor);
  }

  const handleFileChange = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);
    document.getElementById('imagem').src = newFileURL;

    // Restante do código
  };

  const handleFileChange2 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile2(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview2(newFileURL);

    // Restante do código
  };

  const handleFileChange20 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile20(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview20(newFileURL);

    // Restante do código
  };

  const handleFileChange200 = (e) => {

    const newFile = e.target.files[0];

    // Lógica para processar a imagem, se necessário
    setSelectedFile200(newFile);

    // Atualize diretamente o src da imagem
    const newFileURL = URL.createObjectURL(newFile);

    setimagemPreview200(newFileURL);

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (permissaoAtualizar) {

      try {
        toggleLoading(true);


        await updateturma();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const submitInscricaoForm = async () => {

    if (permissaoAtualizar) {

      try {

        // Basic form validation
        if (nome.length < 2) {

          // Show a error toast
          toast.error('O nome deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (nif.length < 5) {

          // Show a error toast
          toast.error('O NIF/BI/PASSPORT deve ter pelo menos cinco (5) caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (telefone.length < 9) {

          // Show a error toast
          toast.error('O telefone deve ter pelo menos digitos (9) caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(email)) {
          // Show a error toast
          toast.error('O email deve ser válido.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (calcularIdade(data_nasc) < 5) {
          // Show a error toast
          toast.error('A idade correspondenrte a esta data a inferior a 5 anos .', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        if (valor == 0 || valor == null || valor < 0)
          Swal.fire({
            title: "Valor de pagamento invalido?",
            text: "Tem a certeza que pretende registar esta inscrição sem pagamento ?",
            type: "warning",
            showCancelButton: !0,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, registar!",
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-danger ml-1",
            buttonsStyling: !1,
          }).then(async function (t) {

            if (t.isConfirmed) {

              await inscrever_novo();

            }

          });
        else await inscrever_novo();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {


          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const updateturma = async () => {
    try {
      const formData = new FormData();
      formData.append('id', id);

      formData.append('dataIni', inputs.dataIni);
      formData.append('dataFim', inputs.dataFim);
      formData.append('hora1', inputs.hora1);
      formData.append('hora2', inputs?.hora2);
      formData.append('local', inputs.local);
      formData.append('obs', inputs.obs);

      formData.append('curso_id', curso_selecionado);
      formData.append('sala_id', sala_selecionada);

      formData.append('periodo', periodo_selecionado);
      formData.append('sabados', sabado_selecionado);
      formData.append('modo', entrega_selecionado);
      formData.append('status', status_selecionado);
      formData.append('promocao', promocao_selecionada);
      formData.append('paga_coletivo', pagamento_selecionado);

      formData.append('preco', inputs.preco);
      formData.append('galeria', galeria);
      formData.append('imagem', imagem);
      formData.append('imagem3', imagem3);

      const response = await http.post('/turma/update', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de turmas após o atualização bem-sucedida
          //history.push('/dream-pos/users/userlists');
          if (!permanecerNaPagina) {
            navegarParaLista();
          }

          getturma();

        }
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const inscrever_novo = async () => {

    try {

      const formData = new FormData();

      setPagamento(inputs.paga_coletivo);

      formData.append('turma_id', id);
      formData.append('tipo_pagamento', tipo_pagamento);
      formData.append('name', nome);
      formData.append('email', email);
      formData.append('nif', nif);
      formData.append('telefone', telefone);
      formData.append('data_nasc', data_nasc);
      formData.append('data_inscricao', data_insc);
      formData.append('genero', genero);
      formData.append('observacao', observacao);
      formData.append('comprovativo', imagem2);
      formData.append('valor', valor);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('dia', obterInfoNaData(data_insc, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc, 2));
      formData.append('ano', obterInfoNaData(data_insc, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/novo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setNome('');
      setEmail('');
      setNIF('');
      setTelefone('')
      setDataNasc('');
      setDataInsc('');
      setObservacao('');
      setValor(0);
      setimagemPreview2('');

      getFormandos();

      getPagamentos();

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitInscricaoFormandoExistenteForm = async () => {

    if (permissaoAtualizar) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende registar esta inscrição ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (formandoSelecionado == undefined || formandoSelecionado == null) {
              // Show a error toast
              toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            if (valor2 == 0 || valor2 == null || valor2 < 0)
              Swal.fire({
                title: "Valor de pagamento invalido?",
                text: "Tem a certeza que pretende registar esta inscrição sem pagamento ?",
                type: "warning",
                showCancelButton: !0,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, registar!",
                confirmButtonClass: "btn btn-primary",
                cancelButtonClass: "btn btn-danger ml-1",
                buttonsStyling: !1,
              }).then(async function (t) {

                if (t.isConfirmed) {

                  await inscrever_formando_existente();

                }

              });
            else await inscrever_formando_existente();


            // If the API call is successful, navigate to the dashboard
            //navigate('/dashboard');

          } catch (error) {
            if (error.response && error.response.status === 401) {
              setError('Utilizador não autenticado.');

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {


              setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }

        }


      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }



  };

  const submitPagamentoForm = async () => {

    if (permissaoAtualizar) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende registar este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (formandoSelecionado == undefined || formandoSelecionado == null) {
              // Show a error toast
              toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            if (valor3 == 0 || valor3 == null || valor3 < 0) {
              // Show a error toast
              toast.error('Valor de pagamento invalido, o valor deve ser um número maior que zero', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            registar_pagamento();

          } catch (error) {
            if (error.response && error.response.status === 401) {
              setError('Utilizador não autenticado.');

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {


              setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const submitSituacaoForm = async () => {

    if (permissaoAtualizar) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende atualizar a situação deste formando ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (formandoSelecionado == undefined || formandoSelecionado == null) {
              // Show a error toast
              toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            registar_situacao();


            // If the API call is successful, navigate to the dashboard
            //navigate('/dashboard');

          } catch (error) {
            if (error.response && error.response.status === 401) {
              setError('Utilizador não autenticado.');

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {


              setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const submitNotaForm = async () => {

    if (permissaoAtualizar) {


      try {

        if (formandoSelecionado == undefined || formandoSelecionado == null) {
          // Show a error toast
          toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        if (nota < 0 || nota > 20) {
          // Show a error toast
          toast.error('O valor da nota deve estar entre 1 á 20', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        registar_nota();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {


          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }






    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const submitComputadorForm = async () => {

    if (permissaoAtualizar) {


      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende atualizar a descrição do computador deste formando ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            if (formandoSelecionado == undefined || formandoSelecionado == null) {
              // Show a error toast
              toast.error('Deve selecionar primeiro um formando na tabela, faça pesquisa caso não consegue ver nenhum formando na tabela .', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }

            if (computador.length < 2) {
              // Show a error toast
              toast.error('A descrição deve ter mais de 1 caracter', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              return;
            }


            registar_computador();


            // If the API call is successful, navigate to the dashboard
            //navigate('/dashboard');

          } catch (error) {
            if (error.response && error.response.status === 401) {
              setError('Utilizador não autenticado.');

              // Show a error toast
              toast.error('Utilizador não autenticado.', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            } else {


              setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

              // Show a error toast
              toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
                position: 'top-right',
                autoClose: 3000, // Close the toast after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

            }
          } finally {
            toggleLoading(false);
          }


        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const inscrever_formando_existente = async () => {

    try {

      const formData = new FormData();

      setPagamento(inputs.paga_coletivo);

      formData.append('formando_id', formandoSelecionado.id);
      formData.append('turma_id', id);
      formData.append('tipo_pagamento', tipo_pagamento);

      formData.append('observacao', observacao2);
      formData.append('comprovativo', imagem20);
      formData.append('valor', valor2);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('data_inscricao', data_insc2);

      formData.append('dia', obterInfoNaData(data_insc2, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc2, 2));
      formData.append('ano', obterInfoNaData(data_insc2, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/existente', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao2('');
      setValor2(0);
      setimagemPreview20('');

      setFormandoSelecionado(null);

      getFormandos();

      getPagamentos();

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registar_pagamento = async () => {

    try {

      const formData = new FormData();

      setPagamento(inputs.paga_coletivo);

      formData.append('turma_id', id);
      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('tipo_pagamento', tipo_pagamento);

      formData.append('observacao', observacao3);
      formData.append('comprovativo', imagem200);
      formData.append('valor', valor3);
      formData.append('tipo_pagamento', tipoPagamento);

      formData.append('data_inscricao', data_insc3);

      formData.append('dia', obterInfoNaData(data_insc3, 3));
      formData.append('mes', 1 + obterInfoNaData(data_insc3, 2));
      formData.append('ano', obterInfoNaData(data_insc3, 1));

      setLoading(true);

      const response = await http.post('/turma/formando/registar_pagamento', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });


      setObservacao3('');
      setValor3(0);
      setimagemPreview200('');

      getFormandos();

      getPagamentos();
      getPagamentosFormando();

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registar_situacao = async () => {

    try {

      const formData = new FormData();

      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('situacao', situacao);

      setLoading(true);

      const response = await http.post('/turma/formando/registar_situacao', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getFormandos();

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {




          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registar_nota = async () => {

    try {

      const formData = new FormData();

      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('nota', nota);


      console.log('inscricao_id', inscricao_selecionada);
      console.log('nota', nota);

      setLoading(true);

      const response = await http.post('/turma/formando/registar_nota', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getFormandos();

      setNota(0);

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registar_nota_td = async (inscricao_selecionada, nota) => {

    try {

      const formData = new FormData();

      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('nota', nota);

      console.log('inscricao_id', inscricao_selecionada);
      console.log('nota', nota);

      setLoading(true);

      const response = await http.post('/turma/formando/registar_nota', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getFormandos();

      setNota(0);

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const registar_computador = async () => {

    try {

      const formData = new FormData();

      formData.append('inscricao_id', inscricao_selecionada);
      formData.append('computador', computador);

      setLoading(true);

      const response = await http.post('/turma/formando/registar_computador', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getFormandos();

      setComputador('');

      const element = document.querySelector('.btn-close');

      // Check if the element exists before triggering the click event
      if (element) {
        // Trigger a click event
        element.click();
      }

      setLoading(false);

      // Show a success toast

      if (response?.data)
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
          }
        });
      else toast.error('Erro : ' + response.error, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.log("Erro:");
      console.log(error);

      toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const remover_pagamento = async (pagamento_id) => {
    if (permissaoAtualizar) {

      Swal.fire({
        title: "Confirmação ?",
        text: "Pretende remover este pagamento ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, registar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (c) {

        if (c.isConfirmed) {

          try {

            const formData = new FormData();

            formData.append('pagamento_id', pagamento_id);

            setLoading(true);

            const response = await http.post('/turma/formando/remover_pagamento', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            getFormandos();

            getPagamentos();
            getPagamentosFormando();

            setLoading(false);

            // Show a success toast

            if (response?.data)
              toast.success(response.data.message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                onClose: () => {
                }
              });
            else toast.error('Erro : ' + response.error, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

          } catch (error) {
            console.log("Erro:");
            console.log(error);

            toast.error('Ocorreu um erro ao carregar o turma. Por favor, tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }

        }
      });


    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "info",
        showCancelButton: !1,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  function calcularIdade(dataNascimento) {

    const hoje = new Date();
    const nascimento = new Date(dataNascimento);

    let idade = hoje.getFullYear() - nascimento.getFullYear();
    const mesAtual = hoje.getMonth();
    const mesNascimento = nascimento.getMonth();

    if (mesAtual < mesNascimento || (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())) {
      idade--;
    }

    return idade;
  }

  const encontrarFormandoPorId = (id) => {
    // Encontre o objeto com a ID correspondente
    const formandoEncontrado = formandos.find((lista) => lista.formando.id == id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }
  };

  //tipo = 1 retorna o ano, tipo = 2 retorna o mês e tipo = 3 retorna o dia
  function obterInfoNaData(data, tipo) {

    const data1 = new Date(data);

    if (tipo == 1)
      return data1.getFullYear();
    else if (tipo == 2)
      return data1.getMonth();
    else
      return data1.getDate();
  }

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionsalas = (salas) => {
    return salas?.map((sala) => (
      <React.Fragment key={sala.id}>
        <option value={sala.id}>{sala.designacao}</option>
      </React.Fragment>
    ));
  };

  const setValorPesquisa = (valor) => {

    setNomeFilter(valor);
    setTelefoneFilter(valor);
    setEmailFilter(valor);
    setNifFilter(valor);

  }

  const handlePesquisarFormandoSubmit = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append('nome', nomeFilter);
      formData.append('telefone', telefoneFilter);
      formData.append('email', emailFilter);
      formData.append('nif', nifFilter);

      const response = await http.post('/utilizador/pesquisar_formando_1_termo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      //console.log(response.data.users);

      // Atualize a lista de utilizadores após resetar a senha
      setFormandoLista(response.data.formandos);

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error('Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setLoading(false);

    }
  };

  const handleSelecionarFormando = (formando) => {

    setFormandoSelecionado(null);

    if (encontrarFormandoPorId(formando.id)) {

      toast.error('Este formando ja esta adicionado a esta turma, não sera possivel adiciona-lo mais de uma vez.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } else {

      setFormandoSelecionado(formando);

      toast.success('formando selecionado.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    }

  }

  const handleRemoverInscricao = (id) => {

    if (permissaoAtualizar) {

      Swal.fire({
        title: "Tem a certeza que pretende remover esta inscrição ?",
        text: "Todos os pagamentos associados a esta inscrição tambem serão removidos, não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {

        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          removerInscricao(id);

        }

      });

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const removerInscricao = async (id) => {

    if (permissaoAtualizar) {

      try {

        const formData = new FormData();
        formData.append('inscricao_id', id);

        const response = await http.post('/turma/remover/inscricao', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        getFormandos();

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



        // Atualize a lista de turmas após ativar/desativar

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao remover a inscrição. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });



      } finally {
        setLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }

  };

  const handleAbrirModalPagamento = (inscricao_id, formando) => {

    setFormandoSelecionado(formando);
    setInscricaoID(inscricao_id);

    getPagamentosFormando();

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_pagamento');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  }

  const handleAbrirModalSituacao = (inscricao_id, formando) => {

    setFormandoSelecionado(formando);
    setInscricaoID(inscricao_id);

    getPagamentosFormando();

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_situacao');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  }

  const handleAbrirModalNota = (inscricao_id, formando) => {

    setFormandoSelecionado(formando);
    setInscricaoID(inscricao_id);

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_nota');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  }

  const handleAbrirModalComputador = (inscricao_id, formando) => {

    setFormandoSelecionado(formando);
    setInscricaoID(inscricao_id);

    // Obtenha a referência do elemento
    const triggerModalPagamento = document.getElementById('trigger_modal_computador');

    // Verifique se o elemento existe antes de chamar o click()
    if (triggerModalPagamento) {
      // Dispare o evento 'click'
      triggerModalPagamento.click();
    }

  }

  const carregarPagamentoAtualizacao = (inscricao_id, formando) => {

    setFormandoSelecionado(formando);
    setInscricaoID(inscricao_id);

    getPagamentosFormando();

  }

  const formandoDaInscricao = (inscricao_id) => {

    const formandoEncontrado = formandos.find((lista) => lista.id == inscricao_id);

    // Verifique se o objeto foi encontrado
    if (formandoEncontrado != undefined) {
      return formandoEncontrado;
    } else {
      // Caso não seja encontrado, você pode retornar null ou fazer algo diferente, dependendo do seu caso
      return null;
    }

  }

  function calcularSomaTotalPagamentos(pagamentos) {
    if (!pagamentos || pagamentos.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos.reduce((total, pagamento) => total + Number(pagamento.valor), 0);
    return somaTotal;
  }

  function calcularTotalPagamentosFormandos(formandos) {
    if (!formandos || formandos.length === 0) {
      return 0;
    }

    const totalPagamentosFormandos = formandos.reduce((total, formando) => {
      if (formando.pagamentos && formando.pagamentos.length > 0) {
        const somaPagamentosFormando = formando.pagamentos.reduce((soma, pagamento) => soma + Number(pagamento.valor), 0);
        return total + Number(somaPagamentosFormando);
      }
      return total;
    }, 0);

    return totalPagamentosFormandos;
  }

  const handleTdNotaKeyPress = (e) => {

    if (e.key === 'Enter') {

      e.preventDefault();

      // Chama a função para registrar a nota
      registar_nota_td(e.target.getAttribute('data-id'), e.target.textContent.trim());
    }

  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-book border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de registo de consultas</h4>
              <h6>Editar consulta</h6>
            </div>
          </div>
          <div className="page-btn">

            <Link to="/dream-pos/turma/novaturma" className="btn btn-added">
              <i className="fa fa-plus"></i>&nbsp;
              Adicionar registo de consulta
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">

            <form className="user">
              <div className="row">

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Tipo De Consulta</label>

                    <select
                      name="semente_id"
                      onChange={(e) => handleSelectCursoChange(e.target.value)}
                      value={curso_selecionado}
                      className="form-control select"
                    >
                      {renderOptioncursos(cursos)}

                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Inicio</label>
                    <input type="date" name="dataIni" id="dataIni" className="form-control" value={inputs.dataIni} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Hora inicio</label>
                    <input type="time" name="hora1" id="hora1" className="form-control" value={inputs.hora1} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Preço ( Kz )</label>
                    <input id="preco" name="preco" type="text" value={inputs.preco} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Aos Sábados</label>

                    <select className="form-control select" onChange={e => handleSelectSabadoChange(e.target.value)} value={sabado_selecionado}>

                      <option value="1" selected={inputs.sabados == 1}>
                        Não
                      </option>
                      <option value="2" selected={inputs.sabados == 2}>
                        Sim
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Estado</label>

                    <select className="form-control select" onChange={e => handleSelectStatusChange(e.target.value)} value={status_selecionado}>

                      <option value="1" selected={inputs.status == 1}>
                      AGUARDANDO CONFIRMAÇÃO
                      </option>
                      <option value="2" selected={inputs.status == 2}>
                      CONFIRMADA
                      </option>
                      <option value="3" selected={inputs.status == 3}>
                      CANCELADA
                      </option>
                      <option value="4" selected={inputs.status == 4}>
                      REALIZADA
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Local</label>
                    <textarea className="form-control" name="local" value={inputs.local} onChange={handleInputsValueChanged}></textarea>
                  </div>

                  <div className="form-group">
                    <label>Plano de saúde ( seguro )?</label>

                    <select className="form-control select" onChange={e => handleSelectPagamentoChange(e.target.value)} value={pagamento_selecionado} >
                      <option value="1" selected={inputs.paga_coletivo == 1}>
                        Não
                      </option>
                      <option value="2" selected={inputs.paga_coletivo == 2}>
                        Sim
                      </option>

                    </select>

                  </div>

                  <div className="form-group">
                    <label>Ativar / Desativar</label>

                    <div className="status-toggle d-flex justify-content-between align-items-center">
                      <input
                        type="checkbox"
                        id={`user${id}`}
                        className="check"
                        defaultChecked={inputs.estado}
                        onChange={() => handleAtivarDesativar(id, !inputs.estado)}
                        name={`estado_${id}`}
                      />
                      <label htmlFor={`user${id}`} className="checktoggle">

                      </label>
                    </div>

                  </div>


                  <div className="form-group">
                    {/* Checkbox "Permanecer na Página" */}
                    <label>
                      <input
                        type="checkbox"
                        checked={permanecerNaPagina}
                        onChange={handlePermanecerNaPagina}
                      />
                      &nbsp; Permanecer na Página depois de salvar
                    </label>
                  </div>


                </div>

                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group">
                    <label>Unidade</label>

                    <select
                      name="semente_id"
                      onChange={(e) => handleSelectSalaChange(e.target.value)}
                      value={sala_selecionada}
                      className="form-control select"
                    >
                      <option value={0}>Escolha a unidade</option>
                      {renderOptionsalas(salas)}
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Data Fim</label>
                    <input id="dataFim" name="dataFim" type="date" className="form-control" value={inputs.dataFim} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Hora fim</label>
                    <input type="time" name="hora2" id="hora2" className="form-control" value={inputs?.hora2} onChange={handleInputsValueChanged} />
                  </div>

                  <div className="form-group">
                    <label>Periodo</label>

                    <select className="form-control select" onChange={e => handleSelectPeriodoChange(e.target.value)} value={periodo_selecionado}>

                      <option value="1" selected={inputs.periodo == 1}>
                        Manhã
                      </option>
                      <option value="2" selected={inputs.periodo == 2}>
                        Tarde
                      </option>
                      <option value="3" selected={inputs.periodo == 3}>
                        Noite
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Modo atendimento</label>

                    <select className="form-control select" onChange={e => handleSelectEntregaChange(e.target.value)} value={entrega_selecionado}>

                      <option value="1" selected={inputs.modo == 1}>
                        Presencial
                      </option>
                      <option value="2" selected={inputs.modo == 2}>
                        Online
                      </option>
                      <option value="3" selected={inputs.modo == 3}>
                        Hibrido
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Paga ?</label>

                    <select className="form-control select" onChange={e => handleSelectPromocaoChange(e.target.value)} value={promocao_selecionada}>

                      <option value="1" selected={inputs.promocao == 1}>
                        Não
                      </option>
                      <option value="2" selected={inputs.promocao == 2}>
                        Sim
                      </option>
                    </select>

                  </div>

                  <div className="form-group">
                    <label>Observação</label>
                    <textarea className="form-control" name="obs" value={inputs.obs} onChange={handleInputsValueChanged}></textarea>
                  </div>

                  <div className="form-group">

                    <label>No slide do website ?</label>

                    <select className="form-control select" onChange={e => handleSelectGaleriaChange(e.target.value)} value={galeria}>

                      <option value="1" selected={inputs.galeria == 1}>
                        Aparece
                      </option>
                      <option value="2" selected={inputs.galeria == 2}>
                        Não aparece
                      </option>
                    </select>

                  </div>


                </div>


                {/* Restante do código */}
                <div className="col-lg-4 col-sm-6 col-12">

                  <div className="form-group row">

                    <a
                      href="#"
                      className="btn btn-secondary col-sm-5"
                      data-bs-toggle="modal"
                      data-bs-target="#add_formador">

                      <i className="fa fa-user"></i> Adicionar doctor

                    </a>


                    {formandos.length < turma?.sala?.acentos ?
                      <a
                        href="#"
                        className="btn btn-warning col-sm-5"
                        data-bs-toggle="modal"
                        data-bs-target="#add_formando">

                        <i className="fa fa-user"></i> Vincular paciente

                      </a> :

                      <a className="btn btn-danger col-sm-5"
                      >

                        Vagas esgotadas

                      </a>

                    }



                  </div>

                  <div className="form-group">
                    <label>Imagem do tipo de consulta</label>
                    <div className="image-upload image-upload-new">
                      <input type="file" onChange={handleFileChange} />
                      <div className="image-uploads">
                        {imagemPreview ? (
                          <>
                            <img id="imagem"
                              src={imagemPreview ? url_root + "/storage/" + imagemPreview : URL.createObjectURL(imagem)}
                              alt="Pré-visualização"
                            />
                            <h4>Carregar imagem</h4>
                          </>
                        ) : (

                          <>
                            <img id="imagem"
                              alt="Pré-visualização"
                            />
                            <h4 className="mb-4">Carregar imagem</h4>
                          </>

                        )}
                      </div>
                    </div>
                  </div>



                  {turma == null ? <span>Carregando...</span> : <>

                    <div className="form-group">
                      <table className="table table-bordered mt-4" width="100%">
                        <thead>
                          <tr>
                            <th colSpan="4">
                              <a className="btn btn-danger"
                                onClick={desvincularFormadorturma}
                              >
                                <i className="fa fa-times"></i>
                                DESASSOCIAR DOCTORES
                              </a>
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="4">

                              <i className="fa fa-users"></i>&nbsp;
                              DOCTOR(ES) DESTA CONSULTA

                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                className="form-control-user"
                                checked={checkGeral}
                                onChange={handleCheckGeralChange}
                              />
                            </th>
                            <th>Foto</th>
                            <th>Nome</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            formadores?.map((cdata, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-control-user check-colaborador"
                                    onChange={handleCheckBoxChange} data-id={cdata?.formador?.id}
                                  />
                                </td>
                                <td className="text-center">
                                  <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.formador?.fotografia} alt="fotografia" />
                                </td>
                                <td>
                                  {cdata?.formador?.name}
                                  <br />
                                  {cdata?.formador?.telefone}
                                  <br />
                                  {cdata?.formador?.email}
                                </td>
                                <td>
                                  <span className="icon" onClick={() => irParaFormador(cdata?.formador?.id)}>
                                    <i className="fa fa-pencil"></i>
                                  </span>
                                </td>
                              </tr>))
                          }
                        </tbody>
                      </table>
                    </div>

                  </>}



                </div>
                {/* Restante do código */}
                <div className="col-lg-4">

                  <a onClick={submitForm} className="btn btn-submit me-2">
                    {loading ? <Spinner /> : 'Salvar'}
                  </a>

                  <a onClick={navegarParaLista} className="btn btn-cancel">
                    Cancelar
                  </a>



                </div>




              </div>
            </form>

          </div>
        </div>
        {/* /add */}



        {/* INICIO formandos da turma*/}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">

                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row table-responsive">


              {turma == null || !permissaoPagamentoVer ? <span>Carregando...</span> : <>


                <table className="table table-bordered table-responsive datanew dataTable no-footer">
                  <thead>
                    <tr>
                      <th colSpan="9">

                        PACIENTE DA CONSULTA - {turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)}

                        <hr />

                      </th>
                      <th colSpan="2">
{/* 
                        <a href={url_root + '/certificados/turma/' + inputs.id} target="_blank" rel="noreferrer" className="btn btn-primary">
                          <i className="fa fa-file"></i>
                          EXPORTAR CERTIFICADOS
                        </a> */}

                        <a className="btn btn-danger"
                          onClick={desvincularFormandosTurma}
                        >
                          <i className="fa fa-times"></i>
                          DESASSOCIAR PACIENTE
                        </a>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="11">

                        <a className="btn btn-success">
                          <i className="fa fa-money"></i>&nbsp;
                          ESPERADO : {formandos.length * turma.preco}
                        </a>
                        &nbsp;&nbsp;

                        <a className="btn btn-warning">
                          <i className="fa fa-money"></i>&nbsp;
                          PAGO : {calcularTotalPagamentosFormandos(formandos)}
                        </a>

                        &nbsp;&nbsp;

                        {(formandos.length * turma.preco) - calcularTotalPagamentosFormandos(formandos) > 0 ?

                          <a className="btn btn-danger">
                            <i className="fa fa-money"></i>&nbsp;
                            EM FALTA : {(formandos.length * turma.preco) - calcularTotalPagamentosFormandos(formandos)}
                          </a>

                          :

                          <></>

                        }

                        &nbsp;&nbsp;

                        {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco) < 50 ?

                          <a className="btn btn-danger"
                            onClick={desvincularFormadorturma}
                          >
                            <i className="fa fa-arrow-down"></i>&nbsp;
                            {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco)}%
                          </a>

                          :

                          <></>

                        }

                        &nbsp;&nbsp;

                        {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco) > 50 && (calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco) < 100 ?

                          <a className="btn btn-warning"
                            onClick={desvincularFormadorturma}
                          >
                            <i className="fa fa-arrow-up"></i>&nbsp;
                            {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco)}%
                          </a>

                          :

                          <></>

                        }

                        &nbsp;&nbsp;

                        {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco) >= 100 ?

                          <a className="btn btn-success"
                            onClick={desvincularFormadorturma}
                          >
                            <i className="fa fa-check"></i>&nbsp;
                            {(calcularTotalPagamentosFormandos(formandos) * 100) / (formandos.length * turma.preco)}%
                          </a>

                          :

                          <></>

                        }


                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="form-control-user"
                          checked={checkGeral2}
                          onChange={handleCheckGeralChange2}
                        />
                      </th>
                      <th>Nº</th>
                      <th>Foto</th>
                      <th>Nome</th>
                      <th>Tel/Email</th>
                      <th>Pagamento (Kz)</th>
                      <th>### <br></br> [ Computador ]</th>
                      <th>###</th>
                      <th>###</th>
                      <th>###</th>
                      <th>
                        Operações &nbsp;&nbsp;

                        <a data-tip="Pdf">
                          <img src={Pdf} alt="img" />
                        </a>&nbsp;


                        <a data-tip="Excel">
                          <img src={Excel} alt="img" />
                        </a>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formandos?.map((cdata, index) => (
                        <tr key={index} className={cdata?.situacao == 1 ? '' : 'text-danger'}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-control-user check-formando"
                              onChange={handleCheckBoxChange2} data-id={cdata?.id}
                            />
                          </td>
                          <td className={cdata?.situacao == 1 ? '' : 'text-danger'}>{index + 1}</td>
                          <td className={cdata?.situacao == 1 ? 'text-center' : 'text-dangertext-center'}>
                            <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.formando.fotografia} alt="fotografia" />
                          </td>
                          <td className={cdata?.situacao == 1 ? '' : 'text-danger'}>{cdata?.formando.name}</td>
                          <td className={cdata?.situacao == 1 ? '' : 'text-danger'}>
                            {cdata?.formando.telefone}<br></br>
                            {cdata?.formando.email}
                          </td>
                          <td >
                            {cdata?.pagamentos && cdata?.pagamentos.length > 0 ? (
                              calcularSomaTotalPagamentos(cdata?.pagamentos)
                            ) : (
                              0
                            )} <span className="text-danger">Falta [ {cdata?.pagamentos ? (
                              turma.preco - calcularSomaTotalPagamentos(cdata?.pagamentos)
                            ) : (
                              0
                            )} ]</span>
                          </td>

                          {cdata?.situacao == 1 ? <>
                            <td>
                              ##
                              <br></br>
                              [ {cdata?.computador} ]
                            </td>

                          </> :
                            <>
                              <td>
                                <span className="text-danger">##</span>
                                <br></br>
                                [ {cdata?.computador} ]
                              </td>


                            </>
                          }

                          <td contentEditable data-id={cdata?.id} className={cdata?.situacao == 1 ? '' : 'text-danger'} onKeyPress={(e) => handleTdNotaKeyPress(e)}>
                            {cdata?.nota}
                          </td>

                          {cdata?.certificado == 0 || cdata?.nota == 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-danger' : ''}>
                              Sem avaliação
                            </td> : ''}

                          {cdata?.certificado == 1 && cdata?.nota != 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-danger' : ''}>
                              Por fazer
                            </td> : ''}

                          {cdata?.certificado == 2 && cdata?.nota != 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-warning' : ''}>
                              Feito
                            </td> : ''}

                          {cdata?.certificado == 3 && cdata?.nota != 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-warning' : ''}>
                              Em homologação
                            </td> : ''}


                          {cdata?.certificado == 4 && cdata?.nota != 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-warning' : ''}>
                              Disponivel
                            </td> : ''}

                          {cdata?.certificado == 5 && cdata?.nota != 0 ?
                            <td className={cdata?.situacao == 1 ? 'text-success' : ''}>
                              Entregue
                            </td> : ''}


                          <td className={cdata?.situacao == 1 ? '' : 'text-danger'}>

                            {cdata?.atendente?.name.toUpperCase()}

                          </td>

                          <td>
                            &nbsp;&nbsp;

                            {cdata?.certificado > 0 ? <><a href={url_root + '/certificado/' + cdata?.id} target="_blank" rel="noreferrer" className="icon link_certificado"><i className="fa fa-file200 text-primary"></i></a></> : <></>}

                            &nbsp;&nbsp;

                            <span className="icon" title="Ver e registar pagamento deste formando" onClick={() => handleAbrirModalPagamento(cdata?.id, cdata?.formando)}>
                              <i className="fa fa-money200 text-warning"></i>
                            </span>&nbsp;&nbsp;

                            <span className="icon" title="Editar nota de avaliação" onClick={() => handleAbrirModalNota(cdata?.id, cdata?.formando)}>
                              <i className="fa fa-table200 text-warning"></i>
                            </span>&nbsp;&nbsp;

                            <span className="icon" title="Editar computador do formando" onClick={() => handleAbrirModalComputador(cdata?.id, cdata?.formando)}>
                              <i className="fa fa-laptop200 text-warning"></i>
                            </span>&nbsp;&nbsp;

                            <span className="icon" onClick={() => irParaFormando(cdata?.formando.id)} title="editar dados do formando" >
                              <i className="fa fa-pencil"></i>
                            </span>&nbsp;&nbsp;
                            <span className="icon" title="Marcar formando como desistente" onClick={() => handleAbrirModalSituacao(cdata?.id, cdata?.formando)}>
                              <i className="fa fa-times-circle text-danger"></i>
                            </span>&nbsp;&nbsp;
                            <span className="icon" title="Remover inscrição" onClick={() => handleRemoverInscricao(cdata?.id)}>
                              <i className="fa fa-trash text-danger"></i>
                            </span>

                          </td>
                        </tr>))
                    }
                  </tbody>
                </table>


              </>}


            </div>
          </div>
        </div>

        {/* FIM formandos da turma */}



        {/* INICIO formandos da turma*/}

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-12 mb-3">
                <div className="input-checkset">
                  <ul>
                    <li>
                      <label className="inputcheck">

                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">


              {turma == null || !permissaoPagamentoVer || !permissaoPagamentoAdicionar ? <span>Carregando...</span> : <>


                <table className="table table-bordered datanew dataTable no-footer">
                  <thead>
                    <tr>
                      <th colSpan="8">
                        PAGAMENTOS DA CONSULTA - {turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)}

                        <hr />
                      </th>
                      <th>

                      </th>
                    </tr>

                  </thead>
                  <thead>
                    <tr>
                      <th>Valor (kz)</th>
                      <th>Percentagem</th>
                      <th>Data</th>
                      <th>Tipo</th>
                      <th>Paciente</th>
                      <th>Comprovativo</th>
                      <th>Atendido</th>
                      <th>Operações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pagamentos_turma?.map((cdata, index) => (
                        <tr key={index}>

                          {cdata?.valor >= cdata?.turma?.preco ? <>
                            <td title={cdata?.observacao} className="text-success">
                              {cdata?.valor}
                            </td>
                          </> : <>{cdata?.turma?.preco / 2 <= cdata?.valor && cdata?.valor < cdata?.turma?.preco ? <>
                            <td title={cdata?.observacao} className="text-warning">
                              {cdata?.valor}
                            </td>
                          </> : <> {cdata?.turma?.preco / 2 > cdata?.valor ? <>
                            <td title={cdata?.observacao} className="text-danger">
                              {cdata?.valor}
                            </td>
                          </> : <></>
                          }</>
                          }</>
                          }







                          <td>
                            {cdata?.valor * 100 / turma?.preco}%
                          </td>
                          <td>
                            {format(new Date(cdata?.data_inscricao), 'dd/MM/yyyy')}
                          </td>
                          <td>
                            {cdata?.tipo_pagamento == 1 ? 'Dinheiro' : cdata?.tipo_pagamento == 2 ? 'Transferência' : cdata?.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                          </td>
                          <td>
                            {formandoDaInscricao(cdata?.inscricao_id)?.formando.name}
                            <br></br>
                            {formandoDaInscricao(cdata?.inscricao_id)?.formando.telefone}
                          </td>
                          <td className="text-center">
                            <img className="img-profile rounded-square" width="50" height="50" src={url_root + "/storage/" + cdata?.comprovativo} alt="fotografia" />
                          </td>
                          <td>
                            {cdata?.atendente?.name.toUpperCase()}
                          </td>
                          <td>
                            <span className="icon" title="Remover pagamento"
                              onClick={() => remover_pagamento(cdata?.id)}>
                              <i className="fa fa-trash"></i>
                            </span>
                          </td>
                        </tr>))
                    }
                  </tbody>




                </table>


              </>}




            </div>
          </div>
        </div>

        {/* FIM formandos da turma */}

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="add_formador" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title">Associar Doctor A Consulta</h5>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button> 

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="form-group">

                        <a className="btn btn-primary"
                          onClick={vincularFormadorturma}>
                          ADICIONAR SELECIONADOS
                        </a>

                      </div>

                      <div className="form-group">

                        <div className="table-responsive">
                          <table className="table table-bordered" id="dataTable" width="100%">
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    className="form-control-user"
                                    id="checkgeral" name="chGeral"
                                    checked={checkGeral}
                                    onChange={handleCheckGeralChange}
                                  />
                                </th>
                                <th>Nº</th>
                                <th>Foto</th>
                                <th>Nome</th>
                                <th>Tel/Email</th>
                                <th>Operações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                colaborador?.map((cdata, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-control-user check-colaborador"
                                        onChange={handleCheckBoxChange} data-id={cdata?.formador?.id}
                                      />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td className="text-center">
                                      <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.formador?.fotografia} alt="fotografia" />
                                    </td>
                                    <td>{cdata?.formador?.name}</td>
                                    <td>
                                      {cdata?.formador?.telefone}<br />
                                      {cdata?.formador?.email}
                                    </td>
                                    <td>
                                      <span className="icon" onClick={() => irParaFormador(cdata?.formador?.id)}>
                                        <i className="fa fa-pencil"></i>
                                      </span>
                                    </td>
                                  </tr>))
                              }
                            </tbody>
                          </table>
                        </div>


                      </div>

                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="add_formando" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title">Associar Paciente A Consulta</h5>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="form-group">

                        <input className="form-control" type="text" onChange={e => setValorPesquisa(e.target.value)} placeholder="digite o nome | email | telefone | NIF a pesquisar e pressione enter..." onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handlePesquisarFormandoSubmit()
                          }
                        }} />

                        <div className="row mt-3">
                          <div className="col-lg-6"><a className="btn btn-primary"
                            onClick={submitInscricaoFormandoExistenteForm}>

                            {loading ? <Spinner /> : 'INSCREVER SELECIONADO NA TABELA'}

                          </a></div>
                          <div className="col-lg-6">
                            <a
                              href="#"
                              className="btn btn-warning"
                              data-bs-toggle="modal"
                              data-bs-target="#add_formando_novo">
                              <i className="fa fa-user"></i> REGISTAR NOVO PACIENTE
                            </a>
                          </div>
                        </div>


                      </div>


                      <div className="row">

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>VALOR PAGO NA CONSULTA</label>
                            <input type="number" name="valor2" id="valor2" className="form-control" value={valor2} onChange={e => setValor2(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TIPO</label>
                            <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                              <option value={1}>Dinheiro</option>
                              <option value={2}>Transferência</option>
                              <option value={3}>TPA</option>
                              <option value={4}>Outro</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label>DATA MARCAÇÃO</label>
                            <input type="date" name="dataInsc2" id="dataInsc2" className="form-control" placeholder="" value={data_insc2} onChange={e => setDataInsc2(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>OBSERVAÇÃO</label>
                            <textarea className="form-control" name="observacao" value={observacao2} onChange={e => setObservacao2(e.target.value)} ></textarea>
                          </div>


                          <div className="form-group">

                            {formandoSelecionado != null ? (
                              <table>
                                {

                                  <tr>
                                    <td className="text-center">
                                      <img className="img-profile rounded-circle" width="50" height="50" src={url_root + "/storage/" + formandoSelecionado.fotografia} alt="fotografia" />
                                    </td>
                                    <td>
                                      {formandoSelecionado.name}
                                      <br />
                                      {formandoSelecionado.telefone}
                                      <br />
                                      {formandoSelecionado.email}
                                    </td>
                                    <td>
                                      <i ></i>
                                    </td>
                                  </tr>
                                }
                              </table>) : <></>

                            }


                          </div>

                        </div>

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>Comprovativo de pagamento</label>
                            <div className="image-upload image-upload-new">
                              <input type="file" onChange={handleFileChange20} />
                              <div className="image-uploads">
                                {imagemPreview20 ? (
                                  <>
                                    <img id="imagem2"
                                      src={imagemPreview20 ? imagemPreview20 : URL.createObjectURL(imagem20)}
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>
                                ) : (

                                  <>
                                    <img id="imagem"
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>

                                )}
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>

                      <div className="form-group">

                        <div className="table-responsive">
                          <table className="table table-bordered" id="dataTable" width="100%">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>Foto</th>
                                <th>Nome</th>
                                <th>Tel/Email</th>
                                <th>Operações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                formandos_lista?.map((cdata, index) => (
                                  <tr key={index} onClick={() => handleSelecionarFormando(cdata)}>

                                    <td>{index + 1}</td>
                                    <td className="text-center">
                                      <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.fotografia} alt="fotografia" />
                                    </td>
                                    <td>{cdata?.name}</td>
                                    <td>
                                      {cdata?.telefone}<br />
                                      {cdata?.email}
                                    </td>
                                    <td>
                                      <span className="icon btn btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close" onClick={() => irParaFormando(cdata?.id)}>
                                        <i className="fa fa-pencil"></i>
                                      </span>
                                    </td>
                                  </tr>))
                              }
                            </tbody>
                          </table>
                        </div>


                      </div>

                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="add_formando_novo" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title">Associar Paciente ( novo registo )</h5>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="row">

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>NOME</label>
                            <input type="text" name="nome" id="nome" className="form-control" placeholder="Digite O Primeiro E o Ultimo Nome" value={nome} onChange={e => setNome(e.target.value)} />
                          </div>
                          <div className="form-group">
                            <label>NIF / BI / PASSAPORTE</label>
                            <input type="text" name="nif" id="nif" className="form-control" placeholder="" value={nif} onChange={e => setNIF(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>DATA NASCIMENTO</label>
                            <input type="date" name="data_nasc" id="data_nasc" className="form-control" placeholder="" value={data_nasc} onChange={e => setDataNasc(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TELEFONE</label>
                            <input type="text" name="telefone" id="telefone" className="form-control" placeholder="" value={telefone} onChange={e => setTelefone(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>VALOR PAGO NA CONSULTA</label>
                            <input type="number" name="valor" id="valor" className="form-control" value={valor} onChange={e => setValor(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TIPO</label>
                            <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                              <option value={1}>Dinheiro</option>
                              <option value={2}>Transferência</option>
                              <option value={3}>TPA</option>
                              <option value={4}>Outro</option>
                            </select>
                          </div>


                          <div className="form-group">
                            <label>OBSERVAÇÃO</label>
                            <textarea className="form-control" name="observacao" value={observacao} onChange={e => setObservacao(e.target.value)} ></textarea>
                          </div>

                        </div>
                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>GENERO</label>
                            <select className="form-control" name="genero" onChange={e => setGenero(e.target.value)} value={genero}>
                              <option value={1}>Feminino</option>
                              <option value={2}>Masculino</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label>E-MAIL</label>
                            <input type="email" name="email" id="email" className="form-control" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>DATA INSCRIÇÃO</label>
                            <input type="date" name="dataInsc" id="dataInsc" className="form-control" placeholder="" value={data_insc} onChange={e => setDataInsc(e.target.value)} />
                          </div>



                          <div className="form-group">
                            <label>Comprovativo de pagamento</label>
                            <div className="image-upload image-upload-new">
                              <input type="file" onChange={handleFileChange2} />
                              <div className="image-uploads">
                                {imagemPreview2 ? (
                                  <>
                                    <img id="imagem2"
                                      src={imagemPreview2 ? imagemPreview2 : URL.createObjectURL(imagem2)}
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>
                                ) : (

                                  <>
                                    <img id="imagem"
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>

                                )}
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="col-lg-4">

                          <a onClick={submitInscricaoForm} className="btn btn-submit me-2">
                            {loading ? <Spinner /> : 'INSCREVER'}
                          </a>

                        </div>


                      </div>




                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }


        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="modal_pagamento" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-money"></i>&nbsp; Registar pagamento</h5>

                    <a id="trigger_modal_pagamento"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_pagamento">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="form-group">

                        <input className="form-control bg-primary text-white" type="text" readOnly value={formandoSelecionado?.name + ' | ' + formandoSelecionado?.telefone + ' | ' + formandoSelecionado?.email + ' | ' + formandoSelecionado?.nif} />

                      </div>

                      <hr />

                      <div className="form-group">

                        <div className="table-responsive">
                          <table className="table table-bordered" id="dataTable" width="100%">
                            <thead>
                              <tr>
                                <th colSpan={6}>PAGAMENTOS DO FORMANDO NESTA TURMA </th>
                                <th>
                                  <a className="btn btn-light" onClick={() => carregarPagamentoAtualizacao(inscricao_selecionada, formandoSelecionado)}>
                                    <i className="fa fa-refresh"></i>&nbsp; ATUALIZAR
                                  </a>
                                </th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>Valor (kz)</th>
                                <th>Percentagem</th>
                                <th>Data</th>
                                <th>Tipo</th>
                                <th>Comprovativo</th>
                                <th>Atendido</th>
                                <th>Operações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                pagamentos_formando?.map((cdata, index) => (
                                  <tr key={index}>
                                    <td title={cdata?.observacao}>
                                      {cdata?.valor}</td>
                                    <td>
                                      {cdata?.valor * 100 / turma?.preco}%
                                    </td>
                                    <td>
                                      {format(new Date(cdata?.data_inscricao), 'dd/MM/yyyy')}
                                    </td>
                                    <td>
                                      {cdata?.tipo_pagamento == 1 ? 'Dinheiro' : cdata?.tipo_pagamento == 2 ? 'Transferência' : cdata?.tipo_pagamento == 3 ? 'TPA' : 'Outro'}
                                    </td>
                                    <td className="text-center">
                                      <img className="img-profile rounded-circle" width="40" height="40" src={url_root + "/storage/" + cdata?.comprovativo} alt="fotografia" />
                                    </td>
                                    <td>
                                      {cdata?.atendente?.name.toUpperCase()}
                                    </td>
                                    <td>
                                      <span className="icon btn btn-close btn-danger" title="Remover pagamento" onClick={() => remover_pagamento(cdata?.id)}>
                                        <i className="fa fa-trash text-danger"></i>
                                      </span>
                                    </td>
                                  </tr>))
                              }
                            </tbody>
                          </table>
                        </div>


                      </div>

                      <hr />


                      <div className="row">

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>VALOR PAGO</label>
                            <input type="number" name="valor3" id="valor3" className="form-control" value={valor3} onChange={e => setValor3(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>TIPO</label>
                            <select className="form-control" name="genero" onChange={e => setTipoPagamento(e.target.value)} value={tipoPagamento}>
                              <option value={1}>Dinheiro</option>
                              <option value={2}>Transferência</option>
                              <option value={3}>TPA</option>
                              <option value={4}>Outro</option>
                            </select>
                          </div>


                          <div className="form-group">
                            <label>DATA</label>
                            <input type="date" name="dataInsc3" id="dataInsc3" className="form-control" placeholder="" value={data_insc3} onChange={e => setDataInsc3(e.target.value)} />
                          </div>

                          <div className="form-group">
                            <label>OBSERVAÇÃO</label>
                            <textarea className="form-control" name="observacao" value={observacao3} onChange={e => setObservacao3(e.target.value)} ></textarea>
                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary"
                              onClick={submitPagamentoForm}>

                              {loading ? <Spinner /> : 'REGISTAR O PAGAMENTO'}

                            </a>

                          </div>


                        </div>

                        <div className="col-lg-6">

                          <div className="form-group">
                            <label>Comprovativo de pagamento</label>
                            <div className="image-upload image-upload-new">
                              <input type="file" onChange={handleFileChange200} />
                              <div className="image-uploads">
                                {imagemPreview200 ? (
                                  <>
                                    <img id="imagem200"
                                      src={imagemPreview200 ? imagemPreview200 : URL.createObjectURL(imagem200)}
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>
                                ) : (

                                  <>
                                    <img id="imagem"
                                      alt="Pré-visualização"
                                    />
                                    <h4>Carregar imagem de comprovativo</h4>
                                  </>

                                )}
                              </div>
                            </div>
                          </div>

                        </div>


                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="modal_situacao" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-money"></i>&nbsp; Registar situação do formando</h5>

                    <a id="trigger_modal_situacao"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_situacao">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="form-group">

                        <input className="form-control bg-primary text-white" type="text" readOnly value={formandoSelecionado?.name + ' | ' + formandoSelecionado?.telefone + ' | ' + formandoSelecionado?.email + ' | ' + formandoSelecionado?.nif} />

                      </div>

                      <hr />

                      <div className="row">

                        <div className="col-lg-12">

                          <div className="form-group">
                            <label>SITUAÇÃO</label>

                            <select className="form-control" value={situacao} onChange={e => setSituacao(e.target.value)}>
                              <option value={1}>Regular</option>
                              <option value={2}>Desistente</option>
                            </select>

                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary" onClick={submitSituacaoForm}>

                              {loading ? <Spinner /> : 'SALVAR'}

                            </a>

                          </div>

                        </div>

                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="modal_nota" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-table"></i>&nbsp; Registar nota do formando</h5>

                    <a id="trigger_modal_nota"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_nota">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr />

                    <form>

                      <div className="form-group">

                        <input className="form-control bg-primary text-white" type="text" readOnly value={formandoSelecionado?.name + ' | ' + formandoSelecionado?.telefone + ' | ' + formandoSelecionado?.email + ' | ' + formandoSelecionado?.nif} />

                      </div>

                      <hr />

                      <div className="row">

                        <div className="col-lg-12">

                          <div className="form-group">
                            <label>Digite A Nota [ 1 á 20 ]</label>

                            <input type="number" id="nota_input" className="form-control" value={nota} onChange={e => setNota(e.target.value)} max={20} min={0} />

                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary" onClick={submitNotaForm}>

                              {loading ? <Spinner /> : 'SALVAR'}

                            </a>

                          </div>

                        </div>

                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

        {
          turma == null ? <span>Carregando...</span> : <>

            {/* Add Event Modal */}
            <div id="modal_computador" className="modal modal-md  fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content modal-lg modal-center">
                  <div className="modal-header">
                    <h5 className="modal-title"> <i className="fa fa-laptop"></i>&nbsp; Registar computador para o formando</h5>

                    <a id="trigger_modal_computador"
                      href="#"
                      className=""
                      data-bs-toggle="modal"
                      data-bs-target="#modal_computador">
                    </a>

                    <button
                      type="button"
                      className="btn btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"></span>
                    </button>

                  </div>

                  <div className="modal-body">

                    <h4>{turma.curso?.designacao.toUpperCase()} | {turma.dataIni} - {turma.dataFim} | {turma.hora1.substring(0, 5)} - {turma?.hora2.substring(0, 5)} </h4>

                    <hr /> 

                    <form>

                      <div className="form-group">

                        <input className="form-control bg-primary text-white" type="text" readOnly value={formandoSelecionado?.name + ' | ' + formandoSelecionado?.telefone + ' | ' + formandoSelecionado?.email + ' | ' + formandoSelecionado?.nif} />

                      </div>

                      <hr />

                      <div className="row">

                        <div className="col-lg-12">

                          <div className="form-group">
                            <label>Digite A Descrição do computador</label>

                            <input type="text" className="form-control" value={computador} onChange={e => setComputador(e.target.value)} />

                          </div>

                          <div className="form-group">

                            <a className="btn btn-primary" onClick={submitComputadorForm}>

                              {loading ? <Spinner /> : 'SALVAR'}

                            </a>

                          </div>

                        </div>

                      </div>



                    </form>

                  </div>

                </div>
              </div>
            </div>
            {/* /Add Event Modal */}

          </>
        }

      </div >

      <ToastContainer />

    </div >
  );
};

TurmaEdit.propTypes = {
  permissaoAtualizar: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoPagamentoVer: PropTypes.bool.isRequired,
  permissaoPagamentoAdicionar: PropTypes.bool.isRequired,
};

export default TurmaEdit;
