/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Tabletop from "../../EntryFile/tabletop";
import { Link } from "react-router-dom";
import "react-select2-wrapper/css/select2.css";
import Swal from "sweetalert2";

import formatNumber from "format-number";

import { useLocation, useHistory } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../components/Spinner";

// Importe o componente Datatable corretamente
import Datatable from "../../EntryFile/datatable";

import generatePDF from "../../components/export/generatePDF";
import generateExcel from "../../components/export/generateExcel";

import { format } from "date-fns";

import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  PlusIcon,
  Printer,
  Search,
  EditIcon,
  DeleteIcon,
  Thomas,
  Benjamin,
  James,
  Bruklin,
  Beverly,
  search_whites,
} from "../../EntryFile/imagePath";

import PropTypes from "prop-types";

const JornadaCientificaLista = ({
  permissaoVer,
  permissaoAtivar,
  permissaoRemover,
  permissaoPagamentoVer,
}) => {
  const location = useLocation();
  const history = useHistory();

  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();
  const [turmas, setturma] = useState([]);
  const [cursos, setcurso] = useState([]);

  const [inputfilter, setInputfilter] = useState(false);

  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ficheiroNome, setFicheiroNome] = useState("");

  const [nomeFilter, setNomeFilter] = useState("");
  const [curso_selecionado, setCursoSelecionado] = useState([]);
  const [data1Filter, setData1Filter] = useState("");
  const [data2Filter, setData2Filter] = useState("");
  const [modoFilter, setModoFilter] = useState("");
  const [precoFilter, setPrecoFilter] = useState("");
  const [frequenciaFilter, setFrequenciaFilter] = useState("");
  const [periodoFilter, setPeriodoFilter] = useState("");
  const [coletivoFilter, setColetivoFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [estadoInscricao, setEstadoInscricao] = useState(0);
  const [rupe, setRUPE] = useState("");
  const [inscricao_id, setInscricaoID] = useState(0);

  const niveis = ["Básico", "Médio", "Avançado", "Expert"];

  //const [permissaoVer, setPermissaoVer] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedturmaIds, setSelectedturmaIds] = useState([]);

  useEffect(() => {
    // Lógica para carregar turmas apenas se a permissão permitir
    if (permissaoVer) {
      const fetchData = async () => {
        try {
          // Chama a função assíncrona getturma()

          await getturma();
          await getcursos();
        } catch (error) {
          console.error("Erro ao obter turmas:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [permissaoVer]);

  const renderOptioncursos = (cursos) => {
    return cursos?.map((curso) => (
      <React.Fragment key={curso.id}>
        <option value={curso.id}>{curso.designacao}</option>
      </React.Fragment>
    ));
  };

  const handleSelectCursoChange = (valor) => {
    setCursoSelecionado(valor);
  };

  const handleFilterSubmit = async () => {
    try {
      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("curso_id", curso_selecionado);
      formData.append("data1", data1Filter);
      formData.append("data2", data2Filter);
      formData.append("modo", modoFilter);
      formData.append("preco", precoFilter);
      formData.append("periodo", periodoFilter);
      formData.append("frequencia", frequenciaFilter);
      formData.append("paga_coletivo", coletivoFilter);
      formData.append("estado", estadoFilter);

      console.log("curso_id", curso_selecionado);
      console.log("data1", data1Filter);
      console.log("data2", data2Filter);
      console.log("modo", modoFilter);
      console.log("preco", precoFilter);
      console.log("periodo", periodoFilter);
      console.log("frequencia", frequenciaFilter);
      console.log("paga_coletivo", coletivoFilter);
      console.log("estado", estadoFilter);

      const response = await http.post("/turma/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      //console.log(response.data.turmas);
      setturma(response.data.turmas);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleAlterarEstados = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("estado", estadoInscricao);
      formData.append("inscricoes", JSON.stringify(selectedturmaIds));

      const response = await http.post(
        "/jornada-cientifica/alterar-estados",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast.success(
        response?.data?.message,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      const triggerModalEstado = document.getElementById("fechar_estado_modal");

      if (triggerModalEstado) {
        // Dispare o evento 'click'
        triggerModalEstado.click();
      }

      getturma();

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro ao atualizar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleAlterarRupe = async () => {
    try {

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("rupe", rupe);
      formData.append("inscricao", inscricao_id);

      const response = await http.post(
        "/jornada-cientifica/alterar-rupe",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      toast.success(
        response?.data?.message,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      const triggerModalEstado = document.getElementById("fechar_rupe_modal");

      if (triggerModalEstado) {
        // Dispare o evento 'click'
        triggerModalEstado.click();
      }

      getturma();

      setLoading(false);

    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro ao atualizar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleChechboxSelectionIds = (selectedRowKeys) => {
    setSelectedturmaIds(selectedRowKeys);
  };

  const handleEliminarMultiplos = async () => {
    if (permissaoRemover) {
      // Exibe a mensagem de confirmação
      Swal.fire({
        title: "Tem certeza que quer eliminar ?",
        text: "Acção de eliminar turmas é irreversivel, pretende continuar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            // Envie uma solicitação para a API para eliminar os usuários selecionados
            const response = await http.post("/turma/eliminar-multiple", {
              selectedturmaIds,
            });

            getturma();

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar os usuários. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedItemChange = async (newState) => {
    if (permissaoAtivar) {
      try {
        // Envie uma solicitação para a API para ativar/desativar os usuários selecionados
        const response = await http.post("/turma/activate-multiple", {
          selectedturmaIds,
          newState,
        });

        getturma();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado dos usuários. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
        // Limpe o estado dos IDs dos usuários selecionados
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getturma = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/inscricoes-jornada-cientifica");

      console.log("inscricções jornada cientifica :");
      console.log(res.data.inscricoes);

      setturma(res.data.inscricoes);

      setLoading(false);

      /* PDF E EXCEL */

      // Verifica se o array de turmas está vazio antes de prosseguir
      if (turmas.length == 0) {
        console.warn("Array de turmas vazio.");
        return;
      }

      const data = turmas?.map((turma) => ({
        name: turma.nome,
        email: turma.email,
        telefone: turma.telefone,
        estado: turma.estado === 1 ? "Ativo" : "Desativado",
      }));

      // Preenche os valores no estado após a conclusão de getturma()
      setTableData(data);

      // Preenche outros estados conforme necessário
      setTableHeader(["Nome", "Email", "Telefone", "Estado"]);
      setFicheiroNome("turmas");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("turma não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getcursos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cursos");

      setcurso(res.data.cursos);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("turma não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const confirmEliminar = (id) => {
    if (permissaoRemover) {
      Swal.fire({
        title: "Tem a certeza que quer remover?",
        text: "Não sera possivel reverter o processo!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(function (t) {
        if (t.isConfirmed) {
          // Se o usuário confirmar, envie a solicitação
          handleEliminar(id);
        }
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const togglefilter = (value) => {
    setInputfilter(value);

    if (!value) {
      setData1Filter(null);
      setData2Filter(null);
      setModoFilter("");
      setPrecoFilter("");
      setFrequenciaFilter("");
      setPeriodoFilter("");
      setColetivoFilter("");
      setEstadoFilter();
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    if (permissaoAtivar) {
      try {
        const estado = newState ? 1 : 0;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", estado);

        const response = await http.post("/turma/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getturma();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de turmas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleEliminar = async (id) => {
    if (permissaoRemover) {
      try {
        const formData = new FormData();
        formData.append("id", id);

        const response = await http.post("/jornada-cientifica/eliminar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        getturma();

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // Atualize a lista de turmas após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  function calcularTotalPagamentosFormandos(pagamentos) {
    if (!pagamentos || pagamentos.length === 0) {
      return 0;
    }

    const somaTotal = pagamentos.reduce(
      (total, pagamento) => total + Number(pagamento.valor),
      0
    );
    return somaTotal;
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      render: (text, record) => <div>ID-{record?.id}</div>,
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => (
        <div>
          <div>
            {format(new Date(record.created_at), "h:i")} -{" "}
            {format(new Date(record.created_at), "dd/MM/yyyy")}
          </div>
        </div>
      ),
    },
    {
      title: "Cursos",
      dataIndex: "sala",
      sorter: (a, b) => a.cursos.length - b.cursos.length,
      render: (text, record) => (
        <div>
          {record?.cursos.map((curso) => (
            <div key={curso.id}>
              <div>
                {curso.curso} - Preço : {curso.preco.toLocaleString()} - Qtd :
                {curso.qtd}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Qtd",
      dataIndex: "qtd",
      sorter: (a, b) => a.qtd.length - b.qtd.length,
      render: (text, record) => (
        <div>{formatarNumero(record?.qtd, 1, ".", ",")}</div>
      ),
    },
    {
      title: "Total ( Kz )",
      dataIndex: "total",
      sorter: (a, b) => a.total.length - b.total.length,
      render: (text, record) => (
        <div>{formatarNumero(record?.total, 1, ".", ",")}</div>
      ),
    },
    {
      title: "RUPE",
      dataIndex: "RUP",
      sorter: (a, b) => a.RUP.length - b.RUP.length,
      render: (text, record) => <div>{record?.RUP}</div>,
    },
    {
      title: "Estado",
      dataIndex: "estado",
      sorter: (a, b) => a.estado.length - b.estado.length,
      render: (text, record) => (
        <div>
          {record?.estado == 0 ? (
            <span className="text-warning">Aguardando referência RUPE</span>
          ) : (
            <></>
          )}
          {record?.estado == 1 ? (
            <span className="text-danger">Aguardando Pagamento</span>
          ) : (
            <></>
          )}
          {record?.estado == 2 ? (
            <span className="text-success">Pago </span>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: (a, b) => a.nome.length - b.nome.length,
      render: (text, record) => (
        <div>
          <div>
            {record?.nome} - {record?.genero} -{" "}
            {(Number(format(new Date(), "yyyy")) -
              Number(format(new Date(record.dataNasc), "yyyy")))}{" "}
            anos
          </div>
          <div>[ {record?.email} ]</div>
          <div>[ {record?.telefone} ]</div>
        </div>
      ),
    },
    {
      title: "Nacionalidade",
      dataIndex: "nacionalidade",
      sorter: (a, b) => a.nacionalidade.length - b.nacionalidade.length,
      render: (text, record) => <div>{record?.nacionalidade}</div>,
    },
    {
      title: "Area de atuação",
      dataIndex: "area_atuacao",
      sorter: (a, b) => a.area_atuacao.length - b.area_atuacao.length,
      render: (text, record) => (
        <div>
          <div>{record?.area_atuacao}</div>
          <div>[ {record?.cargo} ]</div>
        </div>
      ),
    },
    {
      title: "Ação",
      render: (e, record) => (
        <>
         
          <div className="icons-container">
            <div
              title="Alterar RUPE"
              className="confirm-text"
              onClick={() => handleAbrirModalRupe(record.id)}
            >
              <img src={EditIcon} alt="Alterar RUPE" />
            </div>
          </div>
          <div className="icons-container">
            <div
              title="Eliminar"
              className="confirm-text"
              onClick={() => confirmEliminar(record.id)}
            >
              <img src={DeleteIcon} alt="Eliminar registo" />
            </div>
          </div>
        </>
      ),
    },
  ];

  const formatarNumero = (
    numero,
    casasDecimais,
    separadorMilhares,
    separadorDecimal
  ) => {
    const options = {
      round: casasDecimais,
      decimal: separadorDecimal,
      thousand: separadorMilhares,
    };

    return formatNumber(options)(numero);
  };

  const handleAbrirModalRupe = (inscricao_id) => {

    setInscricaoID(inscricao_id);
    // Obtenha a referência do elemento
    const triggerModalRupe = document.getElementById("trigger_modal_rupe");

    if (triggerModalRupe) {
      // Dispare o evento 'click'
      triggerModalRupe.click();
    }
  };

  if (permissaoVer)
    return (
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="titles">
                <i
                  alt="Image"
                  className="header-image fa fa-book border p-3 rounded-3"
                ></i>
                <div>
                  <h4>Jornada Cientifica</h4>
                  <h6>Gestão de inscrições</h6>
                </div>
              </div>
              <a
                href="#"
                className="btn btn-primary col-sm-5"
                data-bs-toggle="modal"
                data-bs-target="#add_formador"
              >
                Alterar estado(s)
              </a>

              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#add_rupe"
                id="trigger_modal_rupe"
              ></a>
            </div>
            {/* /product list */}

            <div className="card">
              <div className="card-body">
                <Tabletop
                  inputfilter={inputfilter}
                  togglefilter={togglefilter}
                  handleSelectedItemChange={handleSelectedItemChange}
                  handleEliminarMultiplos={handleEliminarMultiplos}
                  generatePDF={generatePDF}
                  generateExcel={generateExcel}
                  logoTipo={logoTipo}
                  tableHeader={tableHeader}
                  tableData={tableData}
                  ficheiroNome={ficheiroNome}
                  setNomeFilter={setNomeFilter}
                  handleFilterSubmit={handleFilterSubmit}
                />

                {/* /Filter */}
                <div
                  className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                  id="filter_inputs"
                  style={{ display: inputfilter ? "block" : "none" }}
                >
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            name="curso_id"
                            onChange={(e) =>
                              handleSelectCursoChange(e.target.value)
                            }
                            value={curso_selecionado}
                            className="form-control select"
                          >
                            <option value={-1}>Selecione a consulta</option>
                            {renderOptioncursos(cursos)}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group" title="data 1">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="data1"
                            value={data1Filter}
                            onChange={(e) => setData1Filter(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12" title="data 2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="data2"
                            value={data2Filter}
                            onChange={(e) => setData2Filter(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Preço"
                            value={precoFilter}
                            onChange={(e) => setPrecoFilter(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={modoFilter}
                            onChange={(e) => setModoFilter(e.target.value)}
                          >
                            <option value="-1">Todos modos</option>
                            <option value="1">Presencial</option>
                            <option value="2">Online</option>
                            <option value="3">Hibrido</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={(e) => setEstadoFilter(e.target.value)}
                          >
                            <option value="-1">Todos estados</option>
                            <option value="1">Aguardando Confirmação</option>
                            <option value="2">Confirmada</option>
                            <option value="3">Cancelada</option>
                            <option value="4">Realizada</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={frequenciaFilter}
                            onChange={(e) =>
                              setFrequenciaFilter(e.target.value)
                            }
                          >
                            <option value="-1">Todas as frequências</option>
                            <option value="1">Dias utéis</option>
                            <option value="2">Sábados</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={periodoFilter}
                            onChange={(e) => setPeriodoFilter(e.target.value)}
                          >
                            <option value="-1">Todos os periodos</option>
                            <option value="1">Manhã</option>
                            <option value="2">Tarde</option>
                            <option value="3">Noite</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={coletivoFilter}
                            onChange={(e) => setColetivoFilter(e.target.value)}
                          >
                            <option value="-1">Todos os pacientes</option>
                            <option value="1">Publico</option>
                            <option value="2">Seguro de Saúde </option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                        <div className="form-group">
                          <a className="btn btn-filters ms-auto">
                            <img
                              src={search_whites}
                              alt="img"
                              onClick={handleFilterSubmit}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Filter */}
                <div className="table-responsive">
                  {loading ? (
                    <span>Carregando...</span> // Render the spinner component when loading is true
                  ) : (
                    /*<Table columns={columns} dataSource={turmas} />*/

                    <Datatable
                      columns={columns}
                      dataSource={turmas}
                      onSelectedRowsChange={(selectedturmaIds) =>
                        setSelectedturmaIds(selectedturmaIds)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>

        <div id="add_formador" className="modal modal-md  fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content modal-lg modal-center">
              <div className="modal-header">
                <h5 className="modal-title">Alterar estado da inscrição</h5>

                <button
                  type="button"
                  className="btn btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="fechar_estado_modal"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>

              <div className="modal-body">
                <hr />

                <form>
                  <div className="form-group">
                    <a
                      className="btn btn-primary"
                      onClick={() => handleAlterarEstados()}
                    >
                      CLIQUE AQUI PARA ALTERAR O ESTADO
                    </a>
                  </div>

                  <div className="form-group">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label>ESTADO</label>
                                <select
                                  className="form-control"
                                  onChange={(e) =>
                                    setEstadoInscricao(e.target.value)
                                  }
                                  value={estadoInscricao}
                                >
                                  <option value={0}>Aguardar RUPE</option>
                                  <option value={1}>Aguardar Pagamento</option>
                                  <option value={2}>Pago</option>
                                </select>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div id="add_rupe" className="modal modal-md  fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content modal-lg modal-center">
              <div className="modal-header">
                <h5 className="modal-title">Alterar RUPE</h5>

                <button
                  type="button"
                  className="btn btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="fechar_rupe_modal"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>

              <div className="modal-body">
                <hr />

                <form>
                  <div className="form-group">
                    <a
                      className="btn btn-primary"
                      onClick={() => handleAlterarRupe()}
                    >
                      CLIQUE AQUI PARA ALTERAR O RUPE
                    </a>
                  </div>

                  <div className="form-group">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-group">
                                <label>RUPE</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={(e) => setRUPE(e.target.value)}
                                  placeholder="digite o valor do RUPE"
                                  value={rupe}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

JornadaCientificaLista.propTypes = {
  permissaoVer: PropTypes.bool.isRequired,
  permissaoAtivar: PropTypes.bool.isRequired,
  permissaoRemover: PropTypes.bool.isRequired,
  permissaoPagamentoVer: PropTypes.bool.isRequired,
};

export default JornadaCientificaLista;
